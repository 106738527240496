<template>
  <div class="aboutWanxian">
    <Head :type="4"></Head>
    <Carousel></Carousel>
    <div class="aboutWanxian_con">
      <div class="img_con">
        <div class="wow slideInRight" :class="['about', bgIndex == 1 ? '' : 'about1']" @click="guanyu(1)">
          <span style="margin-right: 25px">关于万线</span>
          <span>ABOUT US</span>
          <img v-if="bgIndex == 1" class="img" src="../assets/img/fuhao.png" alt="" />
        </div>
        <div :class="['qualifications', bgIndex == 2 ? '' : 'qualifications1']" @click="zizhi(2)">
          <span style="margin-right: 25px">资质认证</span>
          <span>CERTIFICATION</span>
          <img v-if="bgIndex == 2" class="img" src="../assets/img/fuhao.png" alt="" />
        </div>
        <div class="wow slideInLeft" :class="['contact', bgIndex == 3 ? '' : 'contact1']" @click="lianxi(3)">
          <span style="margin-right: 25px">联系方式</span>
          <span>CONTACT US</span>
          <img v-if="bgIndex == 3" class="img" src="../assets/img/fuhao.png" alt="" />
        </div>
      </div>
      <div class="about_con" v-if="bgIndex == 1">
        <div class="word_con">
          <div class="title">提高您的生活品质</div>
          <p class="content">
            哈尔滨万线科技开发有限公司（简称万线科技）成立于2018年，是国内领先的互联网生态服务机构。企业集软件研发、信息技术应用、互联网产品
            运营、行业解决方案和渠道销售于一体，专注于“互联网+”新型电子商务模式及企业信息化应用建设。公司将传统的供应链流通体系升级为涵盖
            生产商、销售商、仓储服务商、物流服务商、金融服务商、商户和消费者等共生共赢、互惠互利的全新智慧商业生态体系。
          </p>
        </div>
        <div class="word_con">
          <div class="title">公司构架</div>
          <p class="content">
            公司成立至今秉承以战略创新为方向、以人才培养为保障、以技术提升为手段、以市场拓展为目标的发展理念。万线科技将人才发展置于最高战略
            高度，积极推进“高、精、尖”人才的引进。现阶段公司团队共100余人，软件研发中心有专业的研发工程师20余人，运营中心40人、营销中心
            30人及各职能部门10余人。其中具有5年以上工作经验的员工占总人数的 20 %，具有硕士研究生、本科学历的员工占中人数的 64 %。
          </p>
        </div>
        <div class="architecture_tree">
          <div class="tree_con">
            <div class="level1 wow bounceInDown">◆ 董事长 ◆</div>
          </div>
          <div class="tree_con">
            <div class="trunk wow bounceInDown"></div>
            <div class="level2 wow bounceInDown">◆ 总经理 ◆</div>
          </div>
          <div class="tree_con">
            <div class="trunk wow bounceInDown"></div>
            <div class="branch wow bounceInDown" :style="{ width: (arr.length - 1) * 150 + 'px' }"></div>
            <div class="level_con">
              <div class="level3 wow bounceInDown" v-for="(item, index) in arr" :key="index">
                <span class="leaf"></span>
                <span>◆ {{ item }} ◆</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="qualifications_con" v-if="bgIndex == 2">
        <div class="title">资质认证</div>

        <el-row :gutter="26">
          <el-col :span="6" v-for="(item, index) of qualification">
            <div class="qua_con">
              <div style="height: 400px">
                <!-- <el-image
                  class="imgZ"
                  style="cursor: pointer"
                  :preview-src-list="[item.pictureAddress]"
                  :src="item.pictureAddress"
                  alt=""
                ></el-image> -->

                <img class="imgZ" :src="item.pictureAddress" alt="" />
              </div>
              <p class="word">{{ item.title }}</p>
            </div>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="30">
              <el-col :span="8">
                  <div v-if="qualification[0]" class="qua_con">
                      <div><img class="imgZ" :src="qualification[0].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[0].title}}</p>
                  </div>
              </el-col>
              <el-col :span="8">
                  <div v-if="qualification[1]" class="qua_con">
                      <div><img class="imgZ" :src="qualification[1].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[1].title}}</p>
                  </div>
              </el-col>
              <el-col :span="8">
                  <div v-if="qualification[2]" class="qua_con">
                      <div><img class="imgZ" :src="qualification[2].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[2].title}}</p>
                  </div>
              </el-col>
          </el-row>
          <el-row :gutter="26">
              <el-col :span="6">
                  <div class="qua_con" v-if="qualification[3]">
                      <div style="height:400px"><img class="imgZ" :src="qualification[3].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[3].title}}</p>
                  </div>
              </el-col>
              <el-col :span="6">
                  <div class="qua_con" v-if="qualification[4]">
                      <div style="height:400px"><img class="imgZ" :src="qualification[4].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[4].title}}</p>
                  </div>
              </el-col>
              <el-col :span="6">
                  <div class="qua_con" v-if="qualification[5]">
                      <div style="height:400px"><img class="imgZ" :src="qualification[5].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[5].title}}</p>
                  </div>
              </el-col>
              <el-col :span="6">
                  <div class="qua_con" v-if="qualification[6]">
                      <div style="height:400px"><img class="imgZ" :src="qualification[6].pictureAddress" alt=""></div>
                      <p class="word">{{qualification[6].title}}</p>
                  </div>
              </el-col>
          </el-row>
          <div class="qua_bottom">
              <div class="left">
                  <el-row :gutter="18">
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[7]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[7].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[8]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[8].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[9]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[9].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="18" style="margin-top:27px">
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[10]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[10].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[11]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[11].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="qua_con" v-if="qualification[12]">
                            <div style="height:321px"><img class="imgZ" :src="qualification[12].pictureAddress" alt=""></div>
                        </div>
                    </el-col>
                </el-row>
              </div>
              <div class="right">
                  <div v-if="qualification[13]" style="width:100%;height:100%">
                      <img class="imgZ" :src="qualification[13].pictureAddress" alt="">
                  </div>
              </div>
          </div> -->
      </div>
      <div class="contact_con" v-if="bgIndex == 3">
        <img v-if="contact[0]" class="img_con" :src="contact[0].pictureAddress" alt="" />
        <p v-if="contact[0]" class="company wow pulse">{{ contact[0].corpName }}</p>
        <div id="lianxi">
          <div v-if="contact[0]">
            <div class="contact_info">
              <img class="wow bounceInLeft" src="../assets/img/dizhi.png" alt="" />
              <span class="wow bounceInLeft" data-wow-delay="0.4s">地址：</span>
              <span class="wow bounceInLeft" data-wow-delay="0.8s">{{ contact[0].address }}</span>
            </div>
            <div class="contact_info">
              <img class="wow bounceInLeft" src="../assets/img/youbian-icon.png" alt="" />
              <span class="wow bounceInLeft" data-wow-delay="0.4s">邮箱：</span>
              <span class="wow bounceInLeft" data-wow-delay="0.8s">{{ contact[0].email }}</span>
            </div>
            <div class="contact_info">
              <img class="wow bounceInLeft" src="../assets/img/dianhua-icon.png" alt="" />
              <span class="wow bounceInLeft" data-wow-delay="0.4s">电话：</span>
              <span class="wow bounceInLeft" data-wow-delay="0.8s">{{ contact[0].phone }}</span>
            </div>
          </div>
        </div>
        <div class="QR_code">
          <div class="code_con wow bounceInLeft" v-for="(item, index) in qrCodelist" :key="index">
            <img class="imgBg1" :src="item.codeAddress" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'
import RightSide from '@/components/RightSide.vue'
import { getQualification, getContact, getQrCode } from '@/api/common'
export default {
  name: 'AboutWanxian',
  components: {
    Head,
    Footer,
    Carousel,
    RightSide,
  },
  data() {
    return {
      bgIndex: 1,
      arr: ['市场中心', '市场中心', '运营中心', '研发中心', '人资中心', '财务中心', '总经办', '行政部'],
      qualification: [],
      contact: [],
      qrCodelist: [],
    }
  },
  methods: {
    goMore() {
      this.$router.push('/homeERP')
    },
    guanyu(index) {
      this.bgIndex = index
    },
    zizhi(index) {
      this.bgIndex = index
    },
    lianxi(index) {
      this.bgIndex = index
    },
    // 资质认证
    getQualification() {
      let params = {}
      getQualification(params).then((res) => {
        if (res.data.code == 200) {
          this.qualification = res.data.data
        } else {
        }
      })
    },
    // 联系方式
    getContact() {
      let params = {}
      getContact(params).then((res) => {
        if (res.data.code == 200) {
          this.contact = res.data.data
        } else {
        }
      })
    },
    // 获取二维码
    getQrCode() {
      let params = {}
      getQrCode(params).then((res) => {
        if (res.data.code == 200) {
          this.qrCodelist = res.data.data
        } else {
        }
      })
    },
    show() {
      document.getElementById('lianxi').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
  },
  created() {
    if (this.$route.query.index) {
      this.bgIndex = this.$route.query.index
    }
  },
  mounted() {
    if (this.$route.query.index == 3) {
      this.show()
    }
    this.getQrCode()
    this.getQualification()
    this.getContact()
    let wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {
        console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
      },
    })
    wow.init()
  },
}
</script>
<style scoped lang="less">
.aboutWanxian {
  .aboutWanxian_con {
    width: 1280px;
    margin: 0px auto;
    .img_con {
      width: 100%;
      height: 80px;
      display: flex;
      margin-top: 34px;
      .about,
      .qualifications,
      .contact {
        width: 33%;
        height: 80px;
        text-align: center;
        line-height: 80px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fffefe;
        cursor: pointer;
        overflow: hidden;
      }
      .about {
        background: url(../assets/img/guanyu.png) no-repeat;
        background-size: 100% 100%;
      }
      .qualifications {
        width: 34%;
        background: url(../assets/img/zizhi.png) no-repeat;
        background-size: 100% 100%;
      }
      .contact {
        background: url(../assets/img/lianxi.png) no-repeat;
        background-size: 100% 100%;
      }
      .about1 {
        background: url(../assets/img/guanyu1.png) no-repeat;
        background-size: 100% 100%;
        color: #333333;
      }
      .qualifications1 {
        background: url(../assets/img/zizhi1.png) no-repeat;
        background-size: 100% 100%;
        color: #333333;
      }
      .contact1 {
        background: url(../assets/img/lianxi1.png) no-repeat;
        background-size: 100% 100%;
        color: #333333;
      }
      .img {
        margin-left: 10px;
        position: relative;
        top: 12px;
      }
    }
    .about_con {
      margin-top: 6px;
      overflow: hidden;
      .word_con {
        margin-top: 84px;
        .title {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #008cd7;
          line-height: 40px;
          text-align: center;
        }
        .content {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 46px;
          margin-top: 42px;
        }
      }
    }
    .architecture_tree {
      width: 100%;
      margin: 0 auto;
      margin-top: 149px;
      margin-bottom: 298px;
      .tree_con {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .level1,
        .level2 {
          width: 240px;
          height: 80px;
          background: #008cd7;
          border-radius: 10px;
          text-align: center;
          line-height: 80px;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .level_con {
          display: flex;
        }
        .level3 {
          width: 140px;
          height: 47px;
          background: #008cd7;
          border-radius: 10px;
          text-align: center;
          line-height: 47px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-right: 10px;
          margin-top: 28px;
          position: relative;
        }
        .level3:last-child {
          margin-right: 0px;
        }
        .trunk {
          width: 6px;
          height: 56px;
          background: #008cd7;
          margin: 9px 0px;
        }
        .branch {
          height: 8px;
          background: #008cd7;
        }
        .leaf {
          display: inline-block;
          position: absolute;
          top: -36px;
          left: 50%;
          width: 8px;
          height: 20px;
          margin-left: -4px;
          background: #008cd7;
        }
      }
    }
    .qualifications_con {
      width: 100%;
      margin-bottom: 239px;
      overflow: hidden;
      .title {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #008cd7;
        line-height: 40px;
        margin: 90px 0px;
        text-align: center;
      }
      .qua_con {
        div {
          width: 100%;
          height: 305px;
          overflow: hidden;
        }
        .word {
          font-size: 18px;
          //   font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 8px;
          margin-bottom: 47px;
          text-align: center;
        }
      }
      .qua_bottom {
        display: flex;
        .left {
          width: 780px;
        }
        .right {
          flex: 0 0 500px;
          height: 667px;
          overflow: hidden;
          margin-left: 21px;
        }
      }
    }
    .contact_con {
      .img_con {
        width: 1280px;
        height: 959px;
        display: block;
        margin-top: 90px;
        margin-bottom: 45px;
      }
      .company {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 25px;
      }
      .contact_info {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 35px;
        img {
          display: block;
          margin-right: 17px;
        }
      }
      .QR_code {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 169px;
        margin-bottom: 96px;
        .code_con {
          text-align: center;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 50px;
          transform-style: preserve-3d;
          .imgBg1 {
            width: 218px;
            height: 218px;
            display: block;
            cursor: pointer;
            transition: all 0.4s;
          }
        }
      }
    }
  }
  .imgBg,
  .imgZ {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    transition: all 0.4s;
  }
  .imgBg:hover {
    transform: scale(1.2);
  }
}
</style>